<template>
  <div>
    <SectionHeader title="My Scotland Wedding - Mobile App"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex flex-col gap-20 p-x-50 mb:p-x-0 mb-40">
        <p><span class="font-robBold">Challenge:</span> Develop a mobile experience that helps users discover scotland wedding venues from the comfort of their homes</p>
        <p><span class="font-robBold">Deliverables:</span> Strategy, User research report, Personas, Storyboards, User Journey Map, User Flow Chart, Sketches, UI, and Prototype.</p>
        <p><span class="font-robBold">Role:</span> Product Designer</p>
      </div>
      <img src="@/assets/images/scotland/wed-main.png" alt="" class="w-full mb-40">
    </div>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Goals and Objectives</h1>
        <p class="mt-20">Develop a mobile experience that helps users discover scotland wedding venues from the comfort of their homes</p>
        <h1 class="font-barBold font-24 mt-40 mb-20">Challenges</h1>
        <ul class="mt-0 mb-40">
          <li class="mb-10">Time - Users lack the time commitment it takes to visit multiple wedding venues, and for those out of the country, it adds additional time needed to fly over.</li>
          <li class="mb-10">Budget - Users have difficulty knowing what venues fit into their budgets upfront. Many venues lack up-front pricing until after interest in booking has been made.</li>
          <li>Accessibility and Diversity - Many venues lack the necessary information regarding accessibility or diversity that users need to make an informed decision.</li>
        </ul>
        <h1 class="font-barBold font-24 mt-40 mb-20">Process</h1>
        <p class="m-0">I started the UX design process with preliminary user research to understand the current market and opportunities. I then turned to user research to understand pain points the users face, analyzed the findings and defined the main tasks the product should accomplish. I developed the concept into UI sketches and tested it with possible users. Using Figma, it was then converted into hi-fi prototypes.</p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/scotland/wed-process.png" alt="" class="m-w-1000 w-full">
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="RESEARCH"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Preliminary Research</h1>
        <p class="m-y-20">Since I was unaware of the wedding venue marketing, I conducted a competitive analysis of direct and in-direct competitors to understand key demographics, gaps in the market, and opportunities.</p>
      </div>

      <div class="flex-start-center flex-wrap">
        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/scotland/wedcomp1.png" alt="Visit Scotland logo">

          <p class="font-robBold mb-10">DIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Lots of venue options</p>
          <p class="mt-0 mb-5">Guides for getting married</p>
          <p class="mt-0 mb-5">Honeymoon ideas</p>
          <p class="mt-0 mb-5">Trustworthy brand</p>
          <p class="mt-0 mb-5">Check availability feature</p>
          <p class="mt-0 mb-5">Accessibility information</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Lots of clicks needed</p>
          <p class="mt-0 mb-5">Pricing is not clear</p>
          <p class="mt-0 mb-5">No catering options</p>
          <p class="mt-0 mb-5">Hard to find venue details</p>
          <p class="mt-0 mb-5">Difficult to find photos</p>
          <p class="mt-0 mb-5">Not an intuitive design</p>
        </div>

        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/scotland/wedcomp2.png" alt="Destination Weddings Scotland logo">

          <p class="font-robBold mb-10">DIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Legal information</p>
          <p class="mt-0 mb-5">Personal vibe</p>
          <p class="mt-0 mb-5">Organized design</p>
          <p class="mt-0 mb-5">Sample pricing available</p>
          <p class="mt-0 mb-5">Clear branding</p>
          <p class="mt-0 mb-5">Menu is easy to navigate</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Font is hard to read</p>
          <p class="mt-0 mb-5">Not mobile friendly</p>
          <p class="mt-0 mb-5">No photos of actual venue</p>
          <p class="mt-0 mb-5">No layout/sqft information</p>
          <p class="mt-0 mb-5">No catering menu</p>
          <p class="mt-0 mb-5">No search feature</p>
        </div>

        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/scotland/wedcomp3.png" alt="The Knot logo">

          <p class="font-robBold mb-10">INDIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Helps you keep track</p>
          <p class="mt-0 mb-5">Inclusive design</p>
          <p class="mt-0 mb-5">Photos of layout</p>
          <p class="mt-0 mb-5">360 tour of venues</p>
          <p class="mt-0 mb-5">Venue capacity information</p>
          <p class="mt-0 mb-5">Search by accommodations</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Not a responsive design</p>
          <p class="mt-0 mb-5">Not mobile friendly</p>
          <p class="mt-0 mb-5">Pricing is not upfront</p>
          <p class="mt-0 mb-5">Not an intuitive design</p>
        </div>


        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/scotland/wedcomp4.png" alt="Wedding Wire logo">

          <p class="font-robBold mb-10">INDIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Track venues</p>
          <p class="mt-0 mb-5">Make a guest list</p>
          <p class="mt-0 mb-5">Wedding website builder</p>
          <p class="mt-0 mb-5">Fully responsive</p>
          <p class="mt-0 mb-5">Check availability feature</p>
          <p class="mt-0 mb-5">Reviews and photos from users</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Pricing is not upfront</p>
          <p class="mt-0 mb-5">Lacking accessibility information</p>
          <p class="mt-0 mb-5">Hard to find information</p>
          <p class="mt-0 mb-5">Navigation is very complicated</p>
          <p class="mt-0 mb-5">Inconsistent brand</p>
        </div>
      </div>

      <div class="p-x-30 mb:p-x-0 mt-40">
        <h1 class="font-barBold font-24">User Research and Key Findings</h1>
        <p class="m-y-20">To understand the key demographics within the Scotland Wedding business, I conducted a preliminary survey of those who are planning a Scotland wedding or will be in the near future. After the key demographics were determined, selected a few individuals for interviews to better understand the problem and uncover user pain points.</p>
      </div>

      <div class="flex-center">
        <img src="@/assets/images/scotland/wed-key-find.png" alt="" class="w-full m-w-1000 mb-20">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Personas</h1>
        <p class="mt-20 mb-40">After analyzing the data, I created two persona to assist with communicating information gathered about the prospective end user throughout the design process.</p>
      </div>

      <div class="flex-center-col">
        <img src="@/assets/images/scotland/wed-per1.png" alt="" class="w-full m-w-750 mb-20">
        <img src="@/assets/images/scotland/wed-per2.png" alt="" class="w-full m-w-750 mb-20">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Storyboards</h1>
        <p class="m-y-20">To further stakeholder buy-in, I created two storyboards regarding the effects the new app will have on the end user.</p>
      </div>

      <div class="flex-center gap-20 mb:flex-col mb-20">
        <div class="w-50p mb:w-full"><img src="@/assets/images/scotland/wed-st1.png" class="w-full" alt=""></div>
        <div class="w-50p mb:w-full"><img src="@/assets/images/scotland/wed-st2.png" class="w-full" alt=""></div>
      </div>
    </div>

    <hr class="w-650 m-y-0 border-top-gray mb:w-80p">
  
    <SectionHeader title="IDEATION"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Concept and Strategy</h1>
        <p class="mt-20 mb-40">Using the user journey map, I was able to narrow down the main flow of the app along with potential missing opportunities. This helped create a clear navigation and user flow that will be intuitive for the user.</p>

        <h1 class="font-barBold font-24">User Journey Map and User Flow Chart</h1>
        <p class="m-y-20">To communicate the concept, I generated a user flow chart.</p>

        <div class="flex flex-wrap mb:justify-center">
          <!--Col 1-->
          <div class="m-w-250">
            <div class="bg-blue1 flex-center-col p-y-10">
              <p class="font-robBold m-0">ACTION</p>
              <p class="font-robBold m-0">1. Download app and search</p>
            </div>
            <div class="bg-blue1 p-10">
              <p class="font-robBold bg-blue1 m-0 text-center mb-10">TASK LIST</p>
              <div class="bg-white p-10 h-150">
                <ol class="m-0 prl-20" type="A">
                  <li class="mb-5">Download app</li>
                  <li class="mb-5">Input search location</li>
                  <li class="mb-5">Set desired distance from location</li>
                </ol>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">FEELINGS</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Excited to get started</li>
                  <li class="mb-5">Impatient</li>
                </ul>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">IMPROVEMENT OPPORTUNITIES</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Suggest other locations based on search</li>
                  <li class="mb-5">Show top venues</li>
                </ul>
              </div>
            </div>
          </div>

          <!--Col 2-->
          <div class="m-w-250">
            <div class="bg-blue2 flex-center-col p-y-10">
              <p class="font-robBold m-0">ACTION</p>
              <p class="font-robBold m-0">2. Filter results</p>
            </div>
            <div class="bg-blue2 p-10">
              <p class="font-robBold bg-blue2 m-0 text-center mb-10">TASK LIST</p>
              <div class="bg-white p-10 h-150">
                <ol class="m-0 prl-20" type="A">
                  <li class="mb-5">Filter by price, sqft, accessibility needs, ect</li>
                  <li class="mb-5">Scroll through/view list of venues</li>
                  <li class="mb-5">Click on each possible venues</li>
                </ol>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">FEELINGS</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Intimidated by all the options</li>
                  <li class="mb-5">Frustrated by multiple clicks</li>
                </ul>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">IMPROVEMENT OPPORTUNITIES</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Flippable photos, videos and specifications</li>
                  <li class="mb-5">Price front and center</li>
                </ul>
              </div>
            </div>
          </div>

          <!--Col 3-->
          <div class="m-w-250">
            <div class="bg-blue3 flex-center-col p-y-10">
              <p class="font-robBold m-0">ACTION</p>
              <p class="font-robBold m-0">3. Narrow down choice</p>
            </div>
            <div class="bg-blue3 p-10">
              <p class="font-robBold bg-blue3 m-0 text-center mb-10">TASK LIST</p>
              <div class="bg-white p-10 h-150">
                <ol class="m-0 prl-20" type="A">
                  <li class="mb-5">Open multiple pages for venues of interest</li>
                  <li class="mb-5">Review each specification</li>
                  <li class="mb-5">Review videos, layout, space, accessibility</li>
                </ol>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">FEELINGS</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Happy by all the possibilities</li>
                  <li class="mb-5">Stress about making a choice</li>
                </ul>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">IMPROVEMENT OPPORTUNITIES</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Save venues of interest</li>
                  <li class="mb-5">Compare venue features</li>
                  <li class="mb-5">Easy checklist to see what the venue has and doesn't</li>
                </ul>
              </div>
            </div>
          </div>

          <!--Col 4-->
          <div class="m-w-250">
            <div class="bg-blue4 flex-center-col p-y-10">
              <p class="font-robBold m-0">ACTION</p>
              <p class="font-robBold m-0">4. Contact venue for availability</p>
            </div>
            <div class="bg-blue4 p-10">
              <p class="font-robBold bg-blue4 m-0 text-center mb-10">TASK LIST</p>
              <div class="bg-white p-10 h-150">
                <ol class="m-0 prl-20" type="A">
                  <li class="mb-5">Select venue</li>
                  <li class="mb-5">Find contact information</li>
                  <li class="mb-5">Contact venie about date wait for response</li>
                </ol>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">FEELINGS</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Impatient about response</li>
                  <li class="mb-5">Anxious about availability</li>
                </ul>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">IMPROVEMENT OPPORTUNITIES</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Easy to find contact information</li>
                  <li class="mb-5">Chatbot that can check dates and answered FAQs</li>
                  <li class="mb-5">Reserve your own dates</li>
                </ul>
              </div>
            </div>
          </div>

          <!--Col 5-->
          <div class="m-w-250">
            <div class="bg-blue5 flex-center-col p-y-10">
              <p class="font-robBold m-0">ACTION</p>
              <p class="font-robBold m-0">5. Book venue</p>
            </div>
            <div class="bg-blue5 p-10">
              <p class="font-robBold bg-blue5 m-0 text-center mb-10">TASK LIST</p>
              <div class="bg-white p-10 h-150">
                <ol class="m-0 prl-20" type="A">
                  <li class="mb-5">Reserve dates/check out</li>
                  <li class="mb-5">Enter details</li>
                  <li class="mb-5">Wait for confirmation and next steps</li>
                </ol>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">FEELINGS</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Excited to be finished</li>
                  <li class="mb-5">Nervous something will go wrong</li>
                </ul>
              </div>
              <p class="font-robBold m-0 text-center m-y-10">IMPROVEMENT OPPORTUNITIES</p>
              <div class="bg-white p-10 h-150">
                <ul class="m-0 prl-20">
                  <li class="mb-5">Costs front and center</li>
                  <li class="mb-5">Down payment expected</li>
                  <li class="mb-5">Instant confirmation and next steps for ease of mind</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-center">
        <img src="@/assets/images/scotland/wed-user-flow.png" alt="" class="m-w-1000 mb-20 mb:w-full">
      </div>
      
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Lo-Fi Sketches</h1>
        <p class="mt-20 mb-40">I sketched many options for each screen, constantly referencing the target audience, user pain points, and design objectives to create an intuitive experience that will allow users to virtually tour and reserve Scotland wedding venues from the comfort of their homes.</p>
      </div>

      <div class="flex-center-between gap-20 flex-wrap">
        <img src="@/assets/images/scotland/wed-ske1.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/scotland/wed-ske2.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/scotland/wed-ske3.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/scotland/wed-ske4.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
      </div>

      
      <div class="p-x-30 mb:p-x-0">
        <p class="mt-20 mb-10">Based on my research insights, I prioritized three main objectives:</p>
        <ul class="m-0">
          <li>Provide a platform where users can easily narrow down and search for desired venues;</li>
          <li>Provide a platform with up-front information for each venue with the option to tour it virtually;</li>
          <li>Provide a platform where users can easily reserve the venue straight from the app.</li>
        </ul>
      </div>
    </div>

    <SectionHeader title="PROTOTYPE"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Hi-Fi Prototype</h1>
        <p class="mt-20 mb-40">During this stage, I iterated on my design based on the Lo-Fi usability study findings. I discovered that there was confusion around reserving the venue and checking out, misunderstandings of what the blog post section was, and how to access the user profile. Therefore, I focused on improving the above while finalizing the UI design. </p>
      </div>

      <div class="flex-center gap-20 flex-wrap mb-50">
        <img src="@/assets/images/scotland/wed-hi1.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi2.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi3.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi4.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi5.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi6.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi7.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
        <img src="@/assets/images/scotland/wed-hi8.png" alt="" class="h-340 w-176 mb:w-full mb:h-reset">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Clickable Prototype</h1>
        <p class="mt-20 mb-40"><a href="https://www.figma.com/proto/hd4QGcqcx3MfqhYohNYLsT?embed_host=share&kind=&node-id=520%3A1872&starting-point-node-id=503%3A169" target="_blank" rel="noopener noreferrer">The clickable prototype</a> was created to test and validate the design. It was very useful in understanding how real users navigate the app and I gained realistic insights on what worked well and what required further improvement. The prototype only includes the aspects that I planned to get users on.</p>
      </div>

      <iframe class="w-full h-400 mb-50" style="border: 1px solid rgba(0, 0, 0, 0.1);" src="https://www.figma.com/proto/hd4QGcqcx3MfqhYohNYLsT?embed_host=share&kind=&node-id=520%3A1872&starting-point-node-id=503%3A169" allowfullscreen></iframe>
    </div>

    <hr class="w-650 m-y-0 border-top-gray mb:w-80p">
  
    <SectionHeader title="VALIDATE"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex-center">
        <img src="@/assets/images/scotland/wed-val.png" class="m-h-285 mb:w-full mb:m-h-reset" alt="">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Conclusion</h1>
        <p class="mt-20 mb-40">After reaching back out to our original research participants to share the final designs, I received many compliments on the innovation of this app. Many users shared that they felt many of their pain points were successfully addressed and that they would love to use the app to plan their wedding.</p>
        <p class="text-center font-16 font-barBoldItalic">“This app would be very helpful to planning my future wedding. I would like to have all the information upfront so I can make the best decision!” ~ Usability Participate</p>

        <p class="mt-20 mb-10">Below is the results from our Hi-Fi usability study:</p>
        <ul class="m-0">
          <li>(20% increase) 4 out of 5 users were able to virtually tour a venue</li>
          <li>(60% increase) 5 out of 5 users were able to successfully search for a venue.</li>
          <li>(60% increase) 5 out of 5 users were able to successfully reserve a venue.</li>
          <li>(60% increase) 4 out of 5 users intuitively knew what the blog section was, with the name change.</li>
        </ul>
      </div>
    </div>

    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">

    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">After Thoughts</h1>
        <p class="m-y-20">This UX case study was very insightful for my first project. At the beginning of this project, I found it difficult on how I was going to fit all the needed content in a way that wouldn’t be overwhelming while also being intuitive. However, I feel that starting with Lo-Fi sketches gave me the room to try different placements before investing time into creating a Hi-Fi product. Additionally, I gained valuable feedback from my usability studies that really helped ensure a great user experience.</p>
        <p class="mb-50 font-robItalic">Note: This UX study was done as a learning experience. My Scotland Wedding is not an actual company.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '../components/SectionHeader.vue'
export default {
  components: { SectionHeader },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
